import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Splide from "@splidejs/splide";
import LazyLoad from "vanilla-lazyload";
import barba from "@barba/core";
import decodeBlurHash from "fast-blurhash";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger);

history.scrollRestoration = "manual";

gsap.defaults({
  ease: "power1.inOut",
  duration: 0.5,
});

// Setting up iOS
let iOS =
  !!window.navigator.userAgent.match(/iPad/i) ||
  !!window.navigator.userAgent.match(/iPhone/i);

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const setupLazyLoading = (container, callback) => {
  container.lazyLoading = new LazyLoad({
    container,
    elements_selector: "[data-lazy]",
    callback_loaded: (el) => {
      gsap.to(el, {
        autoAlpha: 1,
        duration: 0.5,
        ease: "power1.inOut",
        onComplete: () => {
          el.dispatchEvent(new Event("lazyloaded"));
        },
      });
    },
  });
};

// --------------------------------------------------
// 📜 Work cards
// --------------------------------------------------

const setupWorkCards = (container) => {
  const cardList = container.querySelector(".works-card-list");
  const allCards = cardList.querySelectorAll(".works-card");

  // Create change effect for each portfolio navigation item

  allCards.forEach((card) => {
    const cardIndex = card.getAttribute("data-image");
    const correspondingImage = document.querySelector(
      `.works-image__wrapper[data-image="${cardIndex}"]`
    );

    ScrollTrigger.create({
      trigger: card,
      start: "top 85",
      end: "bottom 85",
      onToggle: (self) => {
        if (self.isActive) {
          card.classList.add("works-card--in-view");
          gsap.set(correspondingImage, {
            autoAlpha: 1,
          });
          correspondingImage.style.pointerEvents = "auto";
        } else {
          card.classList.remove("works-card--in-view");
          gsap.set(correspondingImage, {
            autoAlpha: 0,
            delay: 0.05,
          });
          correspondingImage.style.pointerEvents = "none";
        }
      },
    });
  });

  // Check if iOS and block default links so that there is no delay while animation ocurring
  if (iOS) {
    // Select all the <a> tags with the class "my-link"
    const fauxLinks = document.querySelectorAll("a.faux-link");

    // Add a "touchend" event listener to each of the selected links
    fauxLinks.forEach((fauxlink) => {
      fauxlink.addEventListener("click", (event) => {
        // Prevent the default behavior of the anchor tag
        event.preventDefault();
      });

      // Store a flag to track if touchmove has occurred
      let touchMoved = false;

      // Get a reference to the element you want to track touch events on
      const targetElement = fauxlink;

      // Add a touchstart event listener to the element
      targetElement.addEventListener("touchstart", (event) => {
        // Reset the flag when touchstart occurs
        touchMoved = false;

        // Add a touchmove event listener to track touch movement
        targetElement.addEventListener("touchmove", () => {
          touchMoved = true;
        });

        // Add a touchend event listener to handle touchend
        targetElement.addEventListener("touchend", () => {
          // Check if touchmove did not occur before touchend
          if (!touchMoved) {
            // Your code to handle touchend without touchmove
            console.log("Touchend without touchmove");
            const url = targetElement.getAttribute("href");
            // Set the location to the new URL
            barba.go(url);
          }

          // Remove the touchmove event listener to avoid memory leaks
          targetElement.removeEventListener("touchmove", () => {
            touchMoved = true;
          });
        });
      });
    });
  }
};

// --------------------------------------------------
// 🪗 Accordion
// --------------------------------------------------

const setupAccordions = (container) => {
  const accordions = container.querySelectorAll(".accordion");

  const toggleAccordion = (accordionButton) => {
    // Close other accordion items
    accordions.forEach((otherAccordion) => {
      const otherAccordionButton = otherAccordion.querySelector("button");
      if (otherAccordionButton !== accordionButton) {
        otherAccordionButton.setAttribute("aria-expanded", "false");
      }
    });

    // Expand the accordion
    if (accordionButton.getAttribute("aria-expanded") !== "true") {
      accordionButton.setAttribute("aria-expanded", "true");
    }
    // Close the accordion
    else {
      accordionButton.setAttribute("aria-expanded", "false");
    }
  };

  accordions.forEach((accordion) => {
    const accordionButton = accordion.querySelector("button");
    accordionButton.addEventListener("click", () => {
      toggleAccordion(accordionButton);
    });
  });
};

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

const setupCarousel = (container) => {
  let carousel = container.querySelector(".carousel");

  let splide = new Splide(carousel, {
    type: "fade",
    rewind: true,
    speed: 0,
    flickPower: 300,
    arrows: false,
    pagination: false,
  });

  carousel.addEventListener("pause", () => {
    splide.Components.Autoplay.pause();
  });

  carousel.addEventListener("resume", () => {
    splide.Components.Autoplay.play();
  });

  // splide.on( 'click', function (event) {

  // } );

  // Trigger lazyloading after splide has cloned the slides
  // splide.on('ready', () => {
  //   setupLazyLoading(carousel.querySelector);
  // })

  splide.mount();

  // Auto height
  if (carousel.classList.contains("carousel--auto-height")) {
    const autoHeight = () => {
      let activeSlide = splide.Components.Elements.slides[splide.index];
      gsap.set(carousel.querySelector(".splide__list"), {
        height: activeSlide.offsetHeight,
      });
    };
    autoHeight();
    splide.on("move", (e) => {
      autoHeight();
      let cs = document.querySelector(".carousel-counter__status");
      cs.innerHTML = splide.index + 1;
    });
  }

  // Click to change slide
  if (carousel.classList.contains("carousel--click-to-next")) {
    let nextButtons = document.querySelectorAll(".carousel");
    if (nextButtons) {
      nextButtons.forEach((item) => {
        // Get the width of the div
        const divWidth = item.clientWidth;
        item.addEventListener("click", function (event) {
          // Get the horizontal position of the click event
          var clickX = event.clientX - item.getBoundingClientRect().left;

          // Calculate the distance from the left and right edges
          var distanceToLeft = clickX;
          var distanceToRight = divWidth - clickX;

          if (distanceToLeft < distanceToRight) {
            splide.go("<");
          } else {
            splide.go(">");
          }
        });
        item.addEventListener("mousemove", function (event) {
          // Get the horizontal position of the cursor within the div
          var cursorX = event.clientX - this.getBoundingClientRect().left;

          // Calculate the distance from the cursor to the left and right edges
          var distanceToLeft = cursorX;
          var distanceToRight = divWidth - cursorX;

          if (distanceToLeft < distanceToRight) {
            console.log("Cursor is closer to the left side of the div");
            item.setAttribute("data-mouse", "left");
          } else {
            console.log("Cursor is closer to the right side of the div");
            item.setAttribute("data-mouse", "right");
          }
        });
      });
    }
  }

  let carouselVideos = document.querySelectorAll("video.work-gallery__image");
  let carouselVideosWithAudio = document.querySelectorAll(
    "video.video-with-audio"
  );
  let unmuteButtons = document.querySelectorAll(".unmute-button");

  // Pausing videos & muting them
  splide.on("move", (e) => {
    carouselVideos.forEach((video) => {
      video.pause();
      // video.muted = true;
    });
    if (unmuteButtons) {
      unmuteButtons.forEach((unmuteButton) => {
        unmuteButton.style.display = "none";
      });
    }
  });

  // Playing videos
  splide.on("moved", (e) => {
    carouselVideos.forEach((video) => {
      video.pause();
    });

    let activeSlide = splide.Components.Elements.slides[splide.index];
    // Get the first direct child element
    var childElement = activeSlide.firstElementChild;
    if (childElement) {
      // Check if the child element is a video element
      if (childElement.tagName.toLowerCase() === "video") {
        childElement.play();
      }
      if (childElement.classList.contains("video-with-audio")) {
        unmuteButtons.forEach((unmuteButton) => {
          unmuteButton.style.display = "block";
        });
      }
    }
  });

  if (unmuteButtons) {
    unmuteButtons.forEach((unmuteButton) => {
      unmuteButton.addEventListener("click", function (event) {
        let activeSlide = splide.Components.Elements.slides[splide.index];
        // Get the first direct child element
        var childElement = activeSlide.firstElementChild;
        if (childElement) {
          if (childElement.classList.contains("video-with-audio")) {
            // Unmute the current currentSlideVideo
            if (childElement.muted) {
              console.log("unmuted");
              carouselVideosWithAudio.forEach((carouselVideoWithAudio) => {
                carouselVideoWithAudio.muted = false;
              });
              // unmuteButton.textContent = "Mute";
              for (var i = 0; i < unmuteButtons.length; i++) {
                unmuteButtons[i].textContent = "Mute";
              }
            } else {
              console.log("muted");
              carouselVideosWithAudio.forEach((carouselVideoWithAudio) => {
                carouselVideoWithAudio.muted = true;
              });
              for (var i = 0; i < unmuteButtons.length; i++) {
                unmuteButtons[i].textContent = "Unmute";
              }
            }
          }
        }
        // Prevent the event from bubbling up
        event.stopPropagation();
      });
    });
  }

  // Video ratio for projects
  if (carouselVideos) {
    carouselVideos.forEach((video) => {
      // Wait for the video to load its metadata
      video.addEventListener("loadedmetadata", function () {
        // Get the video's width and height
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        // Check if the video is in portrait orientation
        if (videoHeight > videoWidth) {
          // If it's in portrait orientation, add the 'portrait' class
          video.classList.add("work-gallery__image--portrait");
        }
      });
    });
  }

  // Serve correct video size relative to screen size
  function updateVideoSources() {
    carouselVideos.forEach((multiSourceVideoElement) => {
      const sources = multiSourceVideoElement.getElementsByTagName("source");
      const screenWidth = window.innerWidth;

      let selectedSource = getSourceByResolution(sources, "original"); // Default to original if no other source matches

      if (screenWidth > 2800) {
        selectedSource = getSourceByResolution(sources, "1080");
      } else if (screenWidth > 560) {
        selectedSource = getSourceByResolution(sources, "720");
      } else if (screenWidth > 390) {
        selectedSource = getSourceByResolution(sources, "540");
      } else {
        selectedSource = getSourceByResolution(sources, "360");
      }

      multiSourceVideoElement.setAttribute(
        "data-src",
        selectedSource.getAttribute("data-src")
      );
      multiSourceVideoElement.load();
    });
  }

  function getSourceByResolution(sources, resolution) {
    for (const source of sources) {
      if (source.getAttribute("data-resolution") === resolution) {
        return source;
      }
    }

    // If the exact resolution isn't found, find the next numerically higher resolution source
    let nextResolution = "original";
    for (const source of sources) {
      const sourceResolution = source.getAttribute("data-resolution");
      if (
        !isNaN(sourceResolution) &&
        sourceResolution > resolution &&
        (nextResolution === "original" || sourceResolution < nextResolution)
      ) {
        nextResolution = sourceResolution;
      }
    }

    for (const source of sources) {
      if (source.getAttribute("data-resolution") === nextResolution) {
        return source;
      }
    }

    return null;
  }

  // Initial update based on viewport width
  updateVideoSources();

  // Update video sources when the window is resized
  // window.addEventListener("resize", updateVideoSources);
  // splide.on('resize', (e) => {
  //   if(carouselVideos) {
  //     updateVideoSources();
  //   }
  // })

  // Counter update and position
  let carouselCounter = document.querySelector(".carousel-counter");
  if (carouselCounter) {
    let counterContainer = document.querySelector(
      ".carousel-counter__container"
    );
    let counterStatuses = document.querySelectorAll(
      ".carousel-counter__status"
    );

    const updateCounter = () => {
      counterStatuses.forEach((counterStatus) => {
        counterStatus.innerHTML = splide.index + 1;
      });
    };

    const positionCounterContainer = () => {
      let activeSlide = splide.Components.Elements.slides[splide.index];
      let activeImageWidth = activeSlide.querySelector(
        ".work-gallery__image"
      ).offsetWidth;
      console.log(activeImageWidth);
      gsap.set(counterContainer, {
        x: activeImageWidth,
      });
    };

    positionCounterContainer();

    splide.on("move", (e) => {
      updateCounter();
      if (counterContainer) {
        positionCounterContainer();
      }
    });
    splide.on("resize", (e) => {
      if (counterContainer) {
        positionCounterContainer();
      }
    });

    // let nextPageButtons = document.querySelectorAll('.next-button__link');
    // nextPageButtons.forEach(button => {
    //     button.addEventListener('click', function(event) {
    //         // Prevent the event from bubbling up
    //         // event.stopPropagation();
    //     });
    // });
  }
};

// --------------------------------------------------
// 🍔 Menu toggle
// --------------------------------------------------

let menuToggle = document.querySelector(".menu-toggle");

menuToggle.addEventListener("click", () => {
  gsap
    .timeline()
    // .add(() => {
    //   document.querySelector('.carousel')?.dispatchEvent(new Event('pause'));
    // })
    // .to('.home-gallery__image-wrap:nth-child(1), .home-gallery__image-wrap:nth-child(3)', {
    //   autoAlpha: 0,
    // })
    .to(".content", {
      autoAlpha: 0,
      duration: 0,
    })
    .to(".navbar__toggle", {
      autoAlpha: 0,
    })
    .to(
      ".navbar__menu",
      {
        autoAlpha: 1,
      },
      "<"
    );
});

// --------------------------------------------------
// 📱🍔 Mobile menu toggle
// --------------------------------------------------

let mobileMenuToggle = document.querySelector(".mobile-menu-toggle");

const closeOutside = (e) => {
  if (
    e.target.closest(".mobile-menu") === null &&
    e.target.closest(".mobile-menu-toggle") === null
  ) {
    closeMobileMenu();
  }
};

const openMobileMenu = () => {
  mobileMenuToggle.setAttribute("aria-expanded", "true");

  return (
    gsap
      .timeline()
      .add(() => {
        document.querySelector(".carousel")?.dispatchEvent(new Event("pause"));
      })
      .set(".mobile-menu", {
        autoAlpha: 1,
      })
      .to(".mobile-menu-toggle__text--open", {
        autoAlpha: 0,
      })
      .to(
        ".mobile-menu-toggle__text--close",
        {
          autoAlpha: 1,
        },
        "<"
      )
      .to(
        "main > *, header",
        {
          x: -100,
          ease: "power3.inOut",
        },
        "<"
      )

      // Handle clicks outside
      .add(() => {
        document.addEventListener("click", closeOutside);
      })
  );
};

const closeMobileMenu = () => {
  mobileMenuToggle.setAttribute("aria-expanded", "false");

  // Remove outside click handler
  document.removeEventListener("click", closeOutside);

  return gsap
    .timeline()
    .add(() => {
      document.querySelector(".carousel")?.dispatchEvent(new Event("resume"));
    })
    .to(".mobile-menu-toggle__text--open", {
      autoAlpha: 1,
    })
    .to(
      ".mobile-menu-toggle__text--close",
      {
        autoAlpha: 0,
      },
      "<"
    )
    .to(
      "main > *, header",
      {
        x: 0,
        clearProps: "x",
        ease: "power3.inOut",
      },
      "<"
    )
    .set(".mobile-menu", {
      autoAlpha: 0,
    });
};

const toggleMobileMenu = () => {
  if (mobileMenuToggle.getAttribute("aria-expanded") === "false") {
    openMobileMenu();
  } else {
    closeMobileMenu();
  }
};

if (iOS) {
  mobileMenuToggle.addEventListener("touchend", () => {
    toggleMobileMenu();
  });
} else {
  mobileMenuToggle.addEventListener("click", () => {
    toggleMobileMenu();
  });
}

// --------------------------------------------------
// 💌 Contact form
// --------------------------------------------------

const setupContactForm = (container) => {
  // Email form animation
  let mailing = document.querySelector(".mailing");

  let mailingCharacters = ["–", "\\", "|", "/"];
  let mailingAnimation = gsap.timeline({
    repeat: -1,
  });
  mailingCharacters.forEach((character) => {
    mailingAnimation.set(
      mailing,
      {
        innerHTML: character,
      },
      "+=.25"
    );
  });

  // Email form
  var form = container.querySelector("form");
  var loader = container.querySelector(".loading");
  var message = container.querySelector(".form-message");
  var fields = {};
  form.querySelectorAll("[name]").forEach(function (field) {
    fields[field.name] = field;
  });

  // Displays all error messages and adds 'error' classes to the form fields with
  // failed validation.
  var handleError = function (response) {
    var errors = [];
    for (var key in response) {
      if (!response.hasOwnProperty(key)) continue;
      if (fields.hasOwnProperty(key)) fields[key].classList.add("error");
      Array.prototype.push.apply(errors, response[key]);
    }
    message.innerHTML = errors.join("<br>");
  };

  var onload = function (e) {
    if (e.target.status === 200) {
      form.querySelectorAll("[name]").forEach(function (field) {
        field.value = "";
      });
      loader.style.display = "none";
      message.innerHTML =
        "Thank you for your message. We will be in touch soon.";
    } else {
      loader.style.display = "none";
      handleError(JSON.parse(e.target.response));
    }
  };

  var submit = function (e) {
    e.preventDefault();
    loader.style.display = "block";
    var request = new XMLHttpRequest();
    request.open("POST", e.target.action);
    request.onload = onload;
    request.send(new FormData(e.target));
    // Remove all 'error' classes of a possible previously failed validation.
    for (var key in fields) {
      if (!fields.hasOwnProperty(key)) continue;
      fields[key].classList.remove("error");
    }
  };
  form.addEventListener("submit", submit);
};

// --------------------------------------------------
// 🗄️ Work info drawer
// --------------------------------------------------

const setupDrawer = (container) => {
  const drawer = container.querySelector(".work-info");
  let workInfoButton = document.querySelector(".work-info__button");

  workInfoButton.addEventListener("click", function () {
    if (workInfoButton.getAttribute("aria-expanded") === "true") {
      workInfoButton.setAttribute("aria-expanded", "false");

      gsap.to(drawer, {
        yPercent: 0,
        ease: "power3.inOut",
        duration: 1,
      });
    } else {
      workInfoButton.setAttribute("aria-expanded", "true");

      gsap.to(drawer, {
        yPercent: -100,
        ease: "power3.inOut",
        duration: 1,
      });
    }
  });

  // Handle outside clicks outside
  document.addEventListener(
    "click",
    function (e) {
      if (
        e.target.closest(".drawer") === null &&
        workInfoButton.getAttribute("aria-expanded") === "true"
      ) {
        e.preventDefault(); // Prevent the default behavior of the click event
        e.stopPropagation(); // Stop the event from propagating further

        workInfoButton.setAttribute("aria-expanded", "false");

        gsap.to(drawer, {
          yPercent: 0,
          ease: "power3.inOut",
          duration: 1,
        });
      }
    },
    true
  );
};

// --------------------------------------------------
// Article page videos
// --------------------------------------------------

const setupArticleVideos = (container) => {
  let videos = container.querySelectorAll(".block-type-customvideo video");

  // Get all play/pause buttons
  let playPauseButtons = container.querySelectorAll(".play-pause-button");

  // Add click event listeners to each button
  playPauseButtons.forEach((button) => {
    button.addEventListener("click", function () {
      // Find the closest video element to the button that was clicked
      const video = button.previousElementSibling;

      // Toggle the play/pause state of the video
      if (video.paused) {
        video.play();
        button.textContent = "Pause";
      } else {
        video.pause();
        button.textContent = "Play";
      }
    });
  });

  // Serve correct video size relative to screen size
  function updateVideoSources() {
    videos.forEach((multiSourceVideoElement) => {
      const sources = multiSourceVideoElement.getElementsByTagName("source");
      const screenWidth = window.innerWidth;

      let selectedSource = getSourceByResolution(sources, "original"); // Default to original if no other source matches

      if (screenWidth > 2800) {
        selectedSource = getSourceByResolution(sources, "1080");
      } else if (screenWidth > 560) {
        selectedSource = getSourceByResolution(sources, "720");
      } else if (screenWidth > 390) {
        selectedSource = getSourceByResolution(sources, "540");
      } else {
        selectedSource = getSourceByResolution(sources, "360");
      }

      multiSourceVideoElement.setAttribute(
        "data-src",
        selectedSource.getAttribute("data-src")
      );
      multiSourceVideoElement.load();
    });
  }

  function getSourceByResolution(sources, resolution) {
    for (const source of sources) {
      if (source.getAttribute("data-resolution") === resolution) {
        return source;
      }
    }

    // If the exact resolution isn't found, find the next numerically higher resolution source
    let nextResolution = "original";
    for (const source of sources) {
      const sourceResolution = source.getAttribute("data-resolution");
      if (
        !isNaN(sourceResolution) &&
        sourceResolution > resolution &&
        (nextResolution === "original" || sourceResolution < nextResolution)
      ) {
        nextResolution = sourceResolution;
      }
    }

    for (const source of sources) {
      if (source.getAttribute("data-resolution") === nextResolution) {
        return source;
      }
    }

    return null;
  }

  // Initial update based on viewport width
  updateVideoSources();
};

// --------------------------------------------------
// ⌛ Page loading animation
// --------------------------------------------------

let spinner = document.querySelector(".spinner");

let spinnerCharacters = ["..", "...", "..", ".", "&nbsp", "&nbsp"];
let spinnerAnimation = gsap.timeline({
  repeat: -1,
});
spinnerCharacters.forEach((character) => {
  spinnerAnimation.set(
    spinner,
    {
      innerHTML: character,
    },
    "+=.25"
  );
});

// --------------------------------------------------
// 📐 Calculating y-position of navbar
// --------------------------------------------------

// Check if the browser supports the custom 'svh' unit
const supportsSvhUnit =
  window.CSS && window.CSS.supports && window.CSS.supports("height", "50svh");
// Calculate the y-position value based on browser support
const yPosition = supportsSvhUnit ? "50svh" : "50vh";

// --------------------------------------------------
// ✨ Page transitions
// --------------------------------------------------

const updateHeader = (currentContainer, nextContainer) => {
  duration = 0;

  let oldState = [];
  if (currentContainer) {
    duration = 0.5;
    oldState = JSON.parse(currentContainer.dataset.state);
  }
  let newState = JSON.parse(nextContainer.dataset.state);

  // console.log(oldState, newState);

  // Menu

  if (newState.menu !== false) {
    gsap.to(".navbar__menu", {
      autoAlpha: 1,
      duration: duration,
    });
  } else {
    gsap.to(".navbar__menu", {
      autoAlpha: 0,
      duration: duration,
    });
  }

  // Menu toggle

  if (newState.menuToggle !== false) {
    gsap.to(".navbar__toggle", {
      autoAlpha: 1,
      duration: duration,
    });
  } else {
    gsap.to(".navbar__toggle", {
      autoAlpha: 0,
      duration: duration,
    });
  }

  // Mobile menu toggle

  if (newState.mobileMenuToggle !== false) {
    gsap.to(".navbar__mobile-toggle", {
      autoAlpha: 1,
      duration: duration,
    });
  } else {
    gsap.to(".navbar__mobile-toggle", {
      autoAlpha: 0,
      duration: duration,
    });
  }

  // Mob menu location

  if (newState.mobileMenuStart !== false) {
    document.querySelector(".mobile-menu").style.justifyContent = "start";
  } else {
    document.querySelector(".mobile-menu").style.justifyContent = "center";
  }

  // Logo

  if (newState.logo !== false) {
    gsap.to(".navbar__logo", {
      autoAlpha: 1,
      duration: duration,
    });
  } else {
    gsap.to(".navbar__logo", {
      autoAlpha: 0,
      duration: duration,
    });
  }

  // Title

  if (newState.title !== false) {
    document.querySelector(".navbar__title").innerHTML = newState.title;

    gsap.to(".navbar__title", {
      autoAlpha: 1,
      duration: duration,
    });
  } else {
    gsap.to(".navbar__title", {
      autoAlpha: 0,
      duration: duration,
    });
  }

  // Close button

  if (newState.closeButton !== false) {
    document
      .querySelector(".navbar__close")
      .setAttribute("href", newState.closeButton);

    gsap.to(".navbar__close", {
      autoAlpha: 1,
      duration: duration,
    });
  } else {
    gsap.to(".navbar__close", {
      autoAlpha: 0,
      duration: duration,
    });
  }
};

barba.hooks.beforeEnter((data) => {
  updateHeader(data.current.container, data.next.container, false);
});

barba.hooks.before((data) => {
  document.documentElement.classList.add("loading");
});
barba.hooks.leave((data) => {
  document.documentElement.classList.remove("loading");
});

barba.hooks.after((data) => {
  ScrollTrigger.refresh();
});

barba.init({
  // debug: true,
  views: [
    // Home view
    {
      namespace: "home",
      beforeEnter(data) {
        data.next.container
          .querySelector(".content")
          .addEventListener("click", () => {
            if (window.matchMedia("(min-width: 600px)").matches) {
              menuToggle.dispatchEvent(new Event("click"));
            } else {
              mobileMenuToggle.dispatchEvent(new Event("click"));
            }
          });
      },
      afterEnter(data) {
        // let firstImage = data.next.container.querySelector('.home-gallery__image');
        // setupLazyLoading(data.next.container);
        // firstImage.addEventListener('lazyloaded', () => {
        //   setupCarousel(data.next.container);
        // })
      },
    },

    // Works view
    {
      namespace: "works",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupWorkCards(data.next.container);

        // If we're coming from a work page we don't want to animate the cards
        if (data.current?.namespace === "work") {
          gsap.set(".works-card, .works-image", {
            autoAlpha: 1,
          });
          // gsap.set('.works-card--first-group, .works-card--second-group, .works-image', {
          //   autoAlpha: 1,
          // })
        }
      },
      afterEnter(data) {
        gsap
          .timeline({
            defaults: {
              ease: "none",
            },
          })

          .to(".works-card", {
            autoAlpha: 1,
            stagger: 0.05,
          })

          .to(
            ".works-image",
            {
              autoAlpha: 1,
            },
            "-=3.5"
          );

        // .to('.works-card--first-group', {
        //   autoAlpha: 1,
        //   stagger: .05
        // })

        // .to('.works-card--second-group', {
        //   autoAlpha: 1
        // })

        // .to('.works-image', {
        //   autoAlpha: 1
        // }, '-=1.8')
      },
    },

    // Work view
    {
      namespace: "work",
      beforeEnter(data) {
        setupDrawer(data.next.container);
        setupCarousel(data.next.container);
      },
      afterEnter(data) {
        setupLazyLoading(data.next.container);
      },
    },

    // Journal view
    {
      namespace: "journal",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
      },
      afterEnter(data) {
        gsap.to(".journal-card", {
          ease: "none",
          autoAlpha: 1,
          stagger: 0.05,
        });
      },
    },
    // Article view
    {
      namespace: "article",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupArticleVideos(data.next.container);
      },
    },
    // About view
    {
      namespace: "about",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupCarousel(data.next.container);
      },
      afterEnter(data) {
        gsap
          .timeline({
            defaults: {
              ease: "none",
            },
          })

          .to("section", {
            autoAlpha: 1,
            stagger: 0.1,
          });
      },
    },
    // Clients view
    {
      namespace: "clients",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupCarousel(data.next.container);
      },
      afterEnter(data) {
        gsap
          .timeline({
            defaults: {
              ease: "none",
            },
          })

          .to("section", {
            autoAlpha: 1,
            stagger: 0.1,
          });
      },
    },
    // Services view
    {
      namespace: "services",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupAccordions(data.next.container);
      },
      afterEnter(data) {
        gsap
          .timeline({
            defaults: {
              ease: "none",
            },
          })

          .to("section", {
            autoAlpha: 1,
            stagger: 0.1,
          });
      },
    },

    // Contact view
    {
      namespace: "contact",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupContactForm(data.next.container);
      },
      afterEnter(data) {
        gsap
          .timeline({
            defaults: {
              ease: "none",
            },
          })

          .to("section", {
            autoAlpha: 1,
            stagger: 0.1,
          });
      },
    },

    // Default view
    {
      namespace: "default",
      beforeEnter(data) {
        setupLazyLoading(data.next.container);
        setupCarousel(data.next.container);
      },
      afterEnter(data) {
        gsap
          .timeline({
            defaults: {
              ease: "none",
            },
          })

          .to("section", {
            autoAlpha: 1,
            stagger: 0.1,
          });
      },
    },
  ],
  transitions: [
    {
      name: "to-home",
      to: {
        namespace: ["home"],
      },
      leave(data) {
        let tl = gsap
          .timeline()

          // // Hide the blurry placeholder images
          // .set(data.next.container.querySelectorAll('.home-gallery__image-wrap'), {
          //   backgroundImage: 'none'
          // })

          // Hide the container
          .to(data.current.container, {
            autoAlpha: 0,
            duration: 0.25,
            ease: "none",
          })

          // Move navbar down
          .to(".navbar", {
            ease: "power3.inOut",
            duration: 1,
            yPercent: -50,
            y: yPosition,
          })
          .set("header", {
            mixBlendMode: "difference",
            color: "var(--white)",
          });

        return tl;
      },
    },
    {
      name: "to-work",
      to: {
        namespace: ["work"],
      },
      leave(data) {
        gsap.set(data.next.container, {
          position: "fixed",
          top: 0,
        });
        ScrollTrigger.killAll();
      },
      enter(data) {
        let tl = gsap.timeline();

        // Move the next container in
        tl.from(data.next.container, {
          xPercent: 100,
          duration: 1,
          ease: "power3.inOut",
        });

        tl.set(data.next.container, {
          position: "static",
        });

        return tl;
      },
    },
    {
      name: "from-work",
      from: {
        namespace: ["work"],
      },
      leave(data) {
        gsap.set(data.current.container, {
          position: "fixed",
          top: 0,
          zIndex: 2,
        });
      },
      enter(data) {
        // Scroll to the card related to the page we're coming from
        let currentPath = data.current.url.path;
        let currentLink = data.next.container.querySelector(
          '.works-card__body[href*="' + currentPath + '"]'
        );
        if (currentLink) {
          window.scrollTo({
            top: currentLink.offsetTop - 73,
            behavior: "instant",
          });
        }

        let tl = gsap.timeline();

        // Move the current container out
        tl.to(data.current.container, {
          xPercent: 100,
          duration: 1,
          ease: "power3.inOut",
        });

        return tl;
      },
    },
    {
      name: "to-article",
      to: {
        namespace: ["article"],
      },
      leave(data) {
        // Sometimes we're not clicking on a card to go to the article
        // e.g. by using the back/forward buttons
        let trigger = false;
        if (data.trigger.nodeType === 1) {
          trigger = data.trigger.closest(".journal-card");
          trigger.classList.add("trigger");
        }

        let journalCardsExceptTrigger = data.current.container.querySelectorAll(
          ".journal-card:not(.trigger)"
        );

        let tl = gsap
          .timeline()

          .set(data.next.container, {
            position: "fixed",
            autoAlpha: 0,
            top: 0,
          })

          // Hide the cards except the one we've clicked
          .to(journalCardsExceptTrigger, {
            autoAlpha: 0,
            duration: 0.25,
            stagger: 0.05,
            ease: "none",
          });

        if (trigger) {
          let topOfPage = gsap.getProperty(
            document.body,
            "--top-page-padding",
            "px"
          );
          topOfPage = parseFloat(topOfPage);

          let triggerOffsetTop = trigger.getBoundingClientRect().top;

          // If the trigger is not at the top of the page
          if (Math.round(triggerOffsetTop) !== topOfPage) {
            tl.call(() => {
              // Create a clone of the trigger and set it to fixed
              let triggerClone = trigger.cloneNode(true);
              trigger.parentNode.appendChild(triggerClone);
              gsap.set(triggerClone, {
                position: "fixed",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 2,
              });

              // Set the containers to the same height
              gsap.set([data.next.container, data.current.container], {
                minHeight: data.current.container.offsetHeight,
              });

              // Move the trigger clone to the trigger's position
              gsap.set(triggerClone, {
                y: triggerOffsetTop,
              });

              // Hide the trigger
              gsap.set(trigger, {
                autoAlpha: 0,
              });

              // Animate the trigger to the top of the page
              gsap.to(triggerClone, {
                duration: 1,
                y: topOfPage,
                ease: "power3.inOut",
              });
            });

            // Make sure the animation is finished before we move on
            tl.to(trigger, {
              duration: 1,
            });
          }
        }

        return tl;
      },
      enter(data) {
        let tl = gsap
          .timeline()

          .call(() => {
            window.scrollTo({ top: 0, behavior: "instant" });
          })

          .to(data.next.container, {
            autoAlpha: 1,
            duration: 0.25,
            ease: "none",
          })

          .set(data.next.container, {
            position: "static",
          });

        return tl;
      },
    },
    {
      name: "from-article",
      from: {
        namespace: ["article"],
      },
      leave(data) {
        gsap.set(data.current.container, {
          position: "fixed",
          top: 0,
          zIndex: 2,
        });
      },
      enter(data) {
        // Scroll to the card related to the page we're coming from
        let currentPath = data.current.url.path;
        let currentLink = data.next.container.querySelector(
          '.journal-card__body[href*="' + currentPath + '"]'
        );
        if (currentLink) {
          let offset = gsap.getProperty(
            document.body,
            "--top-page-padding",
            "px"
          );
          offset = parseFloat(offset);

          window.scrollTo({
            top: currentLink.offsetTop - offset,
            behavior: "instant",
          });
          gsap.set(currentLink.closest(".journal-card"), {
            autoAlpha: 1,
          });
        }

        let tl = gsap.timeline();

        // Hide current container
        tl.to(data.current.container, {
          autoAlpha: 0,
          duration: 0.25,
          ease: "none",
        });

        return tl;
      },
    },
    {
      name: "to-default",
      leave(data) {
        if (window.matchMedia("(max-width: 599px)").matches) {
          let tl = gsap
            .timeline()

            .add(closeMobileMenu())

            // Hide the current container
            .to(data.current.container, {
              autoAlpha: 0,
              duration: 0.25,
              ease: "none",
            });

          return tl;
        } else if (data.current.namespace !== "home") {
          let tl = gsap
            .timeline()

            // Hide the current container
            .to(data.current.container, {
              autoAlpha: 0,
              duration: 0.25,
              ease: "none",
            });

          return tl;
        }
      },
      enter(data) {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "instant" });

        // If we're leaving the home page…
        if (data.current.namespace === "home") {
          let tl = gsap
            .timeline()

            // Hide the containers
            .set([data.next.container, data.current.container], {
              autoAlpha: 0,
            })

            // Move navbar up
            .to(".navbar", {
              ease: "power3.inOut",
              duration: 1,
              yPercent: 0,
              y: 0,
            })
            .set("header", {
              mixBlendMode: "normal",
              color: "var(--black)",
            })

            // Show the next container
            .set(data.next.container, {
              autoAlpha: 1,
            });

          return tl;
        }
      },
    },
  ],
});
